.listing {
  display: flex;
  width: 100%;
  height: 100%;
}

.buySomeShit {
  grid-column: span 4;
  height: 100%;
  margin-top: 3rem;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.emptyBox {
  width: 120px;
  height: 120px;
}

.buyText {
  font-size: 16px;
  font-weight: 500;
}

.buyLink {
  color: var(--primary-purple);
  font-size: 16px;
  cursor: pointer;
}

.buyLink:hover {
  color: var(--primary-red-500);
}
