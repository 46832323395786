.main {
  display: flex;
  align-items: flex-end;
  justify-content: center;

  max-width: var(--max-width);
  margin-left: auto;
  margin-right: auto;
}

.valueProps {
  display: flex;
  flex-direction: column;
  row-gap: 2.5rem;
  padding-top: 4rem;
}

.propTitle {
  font-weight: 400;
  font-size: 20px;
  color: var(--text-main);
  font-family: "Editorial";
}

.propIcon {
  margin-left: auto;
  margin-right: auto;
  width: 80px;
  height: 80px;
}

.propText {
  margin-bottom: 0rem;
  font-size: 14px;
  color: var(--text-body);
  font-weight: 400;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  column-gap: 5rem;
  border-bottom: 1px solid var(--primary-border);
  padding-bottom: 3rem;
  margin-bottom: 3rem;
}

.propBox {
  display: flex;
  flex-direction: column;
  text-align: center;
  row-gap: 0.5rem;
}

.gridText {
  display: flex;
  flex-direction: column;
  text-align: center;
  row-gap: 0px;
}

.title {
  color: var(--text-main);
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 2rem;
}

.title1 {
  color: var(--text-main);
  font-weight: 500;
  font-size: 20px;
  font-family: "Editorial";
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0rem;
}

.container {
  padding-bottom: 5rem;
  width: 100%;
}

.logo {
  width: 160px;
}

.firstSection {
  margin-right: 5rem;
  display: flex;
  flex-direction: column;
}

.prop {
  color: var(--text-main);
  width: 200px;
  line-height: 180%;
  font-family: "Editorial";
  font-size: 15px;
  font-weight: 500;
}

.lastSection {
  display: flex;
  flex-direction: column;
}

.sections {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.socials {
  height: 48px;
  padding-top: 1.5rem;
  display: flex;
  align-items: center;

  width: 160px;
}

.unstyled {
  display: flex;
  align-items: center;
  height: 23px;
  color: var(--text-body);
  font-weight: 300;
  text-decoration: none;
  font-size: 14px;
  margin-bottom: 1rem;
}

.unstyled:hover {
  color: var(--text-main);
}
.social2 {
  margin: 0.5rem;
  margin-left: 0;

  width: 26px;
  height: 26px;
  color: var(--text-main);
}

.social2:hover {
  cursor: pointer;
  transform: translateY(-1px);
}
.social {
  margin: 0.5rem;
  margin-left: 0;

  width: 30px;
  height: 30px;
  color: var(--text-main);
}

.social:hover {
  cursor: pointer;
  transform: translateY(-1px);
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--text-main);
  color: var(--text-main);
  border-radius: 5px;
  width: 160px;
  margin-top: 1.5rem;
  max-width: 236px;
  height: 40px;
  font-weight: 400;
}

.link {
  text-decoration: none;
}

.button:hover {
  cursor: pointer;
  opacity: 0.4;
  box-shadow: rgba(0, 0, 0, 0.15) 0 3px 9px 0;
  transform: translateY(-1px);
}

.credits {
  margin-bottom: 1rem;
  font-size: 14px;
}

.space {
  display: none;
}

@media (max-width: 600px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;
    row-gap: 2rem;
  }
  .space {
    display: inline;
    margin-bottom: 2rem;
  }
  .main {
    margin-top: 3rem;
    justify-content: center;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .propBox {
    align-items: flex-start;
  }

  .propIcon {
    margin-right: auto;
    margin-left: -5px;
  }

  .gridText {
    text-align: start;
  }

  .title {
    font-size: 18px;
  }

  .propTitle {
    font-size: 16px;
  }

  .propText {
    font-size: 14px;
    line-height: 150%;
  }

  .valueProps {
    padding-top: 0rem;
    row-gap: 1.4rem;
  }

  .title1 {
    font-size: 18px;
  }
  .sections {
    flex-direction: column;
    width: 100%;
  }

  .firstSection {
    align-items: center;
    justify-content: center;
    margin-right: 0;
    margin-bottom: 1rem;
    width: 100%;
  }

  .button {
    margin-bottom: 2rem;
  }
  .socials {
    text-align: center;
  }

  .container {
    text-align: center;
  }

  .lastSection {
    align-items: center;
  }
}
