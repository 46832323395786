.stickyNav {
  position: sticky;
  top: 0;
  background-color: var(--text-main);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stickyNav1 {
  position: sticky;
  top: 0;
  background-color: #ba4444;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  background-color: transparent;
  color: white;
  border: none;
  text-decoration: underline;
  cursor: pointer;
}

.button:hover {
  opacity: 0.8;
}

.main {
  max-width: var(--max-width);
  color: white;
  width: 100%;
  font-size: 13px;
  padding: 6px 0px;
  text-align: center;
  letter-spacing: 0.025rem;
}

.underline {
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
  font-size: inherit;
}

.underline:hover {
  opacity: 0.8;
}

.rowGap {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
}

.bold {
  font-weight: 500;
}

@media (max-width: 768px) {
  .main {
    font-size: 9px;
  }

  .rowGap {
    gap: 0.5rem;
  }
}
