.showProfileContainer {
  display: flex;
  flex-direction: column;
  /* row-gap: 1rem; */
  margin-left: auto;
  margin-right: auto;
  max-width: var(--max-width);
  margin-bottom: 5em;
}

.showPageContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;

  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  column-gap: 1rem;
  margin-left: auto;
  width: 100%;
}

.alignCenter {
  display: grid;
  gap: 0.75rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: space-evenly;
  margin-bottom: 2.5em;
}

.aboutSection {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  align-items: center;
  margin-bottom: 2rem;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
.colGap1 {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.aboutTitle {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0rem;
  text-align: center;
  font-family: "Editorial";
}

.aboutImg {
  width: 100%;
  max-width: 500px;
  height: 300px;
  object-fit: cover;
  border-radius: 5px;
}

.aboutText {
  text-align: center;
  font-size: 14px;
  font-family: "Editorial";
  line-height: 150%;
  margin-bottom: 0rem;
  white-space: pre-wrap;
}

.listing {
  display: flex;
  border-radius: 8px;
  background-color: white;
}

.main {
  display: flex;
  flex-direction: column;
}

.headerBox {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
}

.header {
  width: 20%;
  font-size: 20px;
  margin-top: 1em;
  font-weight: 800;
}

.listing:hover {
  transform: translateY(-1px);
}
.filterBox {
  background-color: transparent;
  min-width: 15em;
  width: 25%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
  row-gap: 1rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.filterBy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0px;
  height: 45px;
  width: 90%;
}

.rightSection {
  width: 80%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.mainFilterContainer {
  display: flex;
  align-items: center;
  width: 100%;
  height: 45px;

  font-size: 20px;
  font-weight: 600;
  justify-content: space-between;
}

.searchBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: inherit;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 14px;
  /* background-color: transparent; */
  transition: all 0.1s ease-in-out;
  border-radius: 3px !important;
  border: 1px solid var(--primary-border);
  position: relative;
  min-height: 43px;
}

.searchIcon {
  width: 15px;
  height: 15px;
  color: var(--text-body);
}

.badgeBox {
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  flex-wrap: wrap;
  height: inherit;
}

.searchBarInput {
  color: none;
  background-color: none;
  border-style: none;
  border-width: 1px;
  width: 100%;
  height: 100%;
  outline: none;
}

.searchBar:hover {
  border: 1px solid var(--text-main);
}

.selectionContainer {
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  /* padding-left: calc(120px + 0.5rem); */
  border-bottom: 1px solid var(--primary-border);
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.selection {
  font-size: 16px;
  text-transform: capitalize;
  padding-bottom: 1rem;
  position: relative;
  cursor: pointer;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-weight: 400;
  color: var(--text-body);
  padding-top: 0.75rem;

  margin-bottom: 0rem;
  transition: all 0.1s ease-in-out;
}

.selection:hover {
  color: var(--text-main);
}

.selectionActive {
  font-size: 16px;
  text-transform: capitalize;
  padding-bottom: 1rem;
  position: relative;
  cursor: pointer;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-weight: 400;
  color: var(--text-body);
  padding-top: 0.75rem;

  margin-bottom: 0rem;
  transition: all 0.1s ease-in-out;
  border-bottom: 1px solid var(--text-main);
}

.selection .borderBottom {
  position: absolute;
  left: 50%; /* Start from the middle */
  bottom: 0;
  height: 1px;
  width: 0; /* Initial width, make it 0 to make the border grow from center */
  background-color: var(--text-main);
  z-index: 4;
  transition: width 0.3s, left 0.3s; /* Transition for width and left properties */
  transform: scaleX(0); /* Set initial scale to 0 to be invisible at first */
  transform-origin: center; /* Set the transform origin to the center */
}

.selection:hover .borderBottom {
  width: 80%; /* Full width on hover */
  left: 10%; /* Adjust left to accommodate full width */
  transform: scaleX(1); /* Scale to full size on hover */
  transition: transform 0.3s, width 0.3s, left 0.3s; /* Add transition for transform */
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.badge {
  display: flex;
  align-items: center;
  background-color: var(--primary-bg);
  border: 1px solid var(--primary-border);
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  padding-left: 0.8em;
  padding-right: 0.5em;
  border-radius: 20px;
  height: 100%;
  cursor: pointer;
}
.badgeText {
  font-size: 14px !important;
  margin-bottom: 0rem;
}

.deleteIcon {
  height: 15px;
  width: 15px;
  margin-left: 2px;
}

.color {
  color: var(--primary-purple);
  transition: linear 1s;
}
.mobile {
  display: flex;
  align-items: center;
}

.mobileDelete {
  position: fixed !important;
  bottom: 30px;
  left: 20px;
  z-index: 5;
}

/* Modal */
.modalHeader {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid #ecedf1;
}

.modalClose {
  height: 2rem;
  width: 2rem;
  color: #606060;
}
.deleteButton {
  background-color: var(--text-main);
  color: white;
  border-radius: 3px;
  padding: 0.5rem 0.7rem;
  position: fixed !important;
  bottom: 30px;
  left: 20px;
  z-index: 3;
}
.modalHeaderText {
  font-size: 14px;
  font-weight: 1000;
}

.modalCloseText {
  font-size: 14px;
  color: var(--text-main);
  font-weight: 400;
}

.modalBody {
  overflow: scroll;
  padding-bottom: 10rem;
}

.displayNone {
  position: absolute;
  cursor: auto !important;
  visibility: hidden !important;
  background-color: transparent;
}

.listingsLength {
  padding-top: 0.2rem;
  font-weight: 300;
  font-size: 13px;
  color: var(--text-mute);
}

.notVisibleMobile {
  display: flex;
}

.visibleMobile {
  display: none;
}

@media (min-width: 990px) {
  .mobile {
    display: none !important;
  }
  .mobileDelete {
    display: none;
  }
}
@media (max-width: 990px) {
  .visibleMobile {
    display: flex;
  }

  .notVisibleMobile {
    display: none;
  }
  .Modal {
    width: 90% !important;
  }
  .header {
    margin-top: 1.5rem;
    font-size: 20px;
    width: 100%;
    padding-left: 1rem;
  }

  .alignCenter {
    grid-template-columns: 1fr 1fr 1fr;

    gap: 0rem;
    width: 100%;
  }

  .rightSection {
    width: 100%;
  }

  .filterButtonProfile {
    margin-left: 0rem;
  }

  .mainFilterContainer {
    padding-left: 0rem;
    padding-top: 0rem;
    min-height: 0rem;
    margin-left: 0rem;
    column-gap: 1rem;
    height: fit-content;
    min-height: 45px;
    flex-direction: column-reverse;
    row-gap: 0.5rem;
    align-items: flex-start;
  }
  .searchBar {
    width: 100%;
    min-width: 0;
    height: 45px;
  }

  .filterBox {
    display: none;
  }
}

@media (max-width: 600px) {
  .alignCenter {
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
  }

  .main {
    padding: 0rem 0rem;
  }

  .selectionContainer {
    padding-left: 0rem;
    justify-content: center;
    margin-bottom: 1rem;
  }

  .selection {
    font-size: 14px;
  }

  /* .listing {
    min-width: 0;
    width: 45vw;
  } */
  .rightSection {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}

.TabContainer {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  height: auto;
  border-bottom: 2px solid var(--primary-bg);
  border-top: 0.5px solid var(--primary-bg);
}

.tabText {
  font-size: 16px;
  text-align: center;
  margin-bottom: 1em;
  color: #99a3a6;
}
.tabTextActive {
  font-size: 16px;
  text-align: center;
  margin-bottom: 1em;
  color: var(--primary-purple);
}

.tabActive {
  color: var(--primary-purple) !important;
  border-bottom: 2px solid var(--primary-purple);
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  width: 7em;
  font-weight: 600;
}

.tab {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  width: 7em;
  margin-bottom: 5px;
}

.tabNum {
  text-align: center;
  font-size: 18px;
}

.tab:hover {
  cursor: pointer;
}
